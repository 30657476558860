import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { userRole } from '@core/constants/app.constants';
import { LOCAL_STORAGE_CONSTANT } from '@core/constants/localstorage.constant';
import { LocalStorageService } from '@core/services/local-storage.service';
import { UtilityService } from '@core/services/utility.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanMatch {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private utilityService: UtilityService
  ) {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.LOGIN_TOKEN) as string;
    const role = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.USER_ROLE) as string;
    const userData = this.utilityService.getUserFromLocalStorage();
    let url = '';
    segments?.forEach(segment => {
      url += `${segment.path}/`;
    })

    if ((!token || url.includes('logout')) && !url.includes('set-password')) {
      return true;
    } else {
      if (role === userRole.admin) {
        return this.router.parseUrl('/admin');
      } else if (role === userRole.partner) {
        if (url.includes('set-password') && userData?.isPasswordReset) {
          return true;
        }
        return this.router.parseUrl(userData?.isPasswordReset ? '/auth/set-password' : '/partner');
      } else {
        return this.router.parseUrl('/auth');
      }
    }
  }
}

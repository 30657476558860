import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { importProvidersFrom, LOCALE_ID } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app/app.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeCH from '@angular/common/locales/de-CH';
import { appRoutes } from './app/core/constants/app.routes';
import { ErrorInterceptor } from './app/core/interceptors/error.interceptor';
import { HttpTokenInterceptor } from './app/core/interceptors/http-token.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeDe, 'de');
registerLocaleData(localeDeCH, 'de_CH');

bootstrapApplication(AppComponent,
  {
    providers: [
      importProvidersFrom(
        [RouterModule.forRoot(appRoutes)],
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
          }
        }),
        MatSnackBarModule,
        MatDialogModule
      ),
      
      { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
      {
        provide: LOCALE_ID, useValue: 'de-ch'
      },
    ],
  }).catch(err => console.log(err));

  export function getLocalStorage() {
    return typeof window !== 'undefined' ? window.localStorage : null;
  }
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { userRole } from '@core/constants/app.constants';
import { LOCAL_STORAGE_CONSTANT } from '@core/constants/localstorage.constant';
import { LocalStorageService } from '@core/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const role = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.USER_ROLE) as string;
    const url = state.url;

    if (url.startsWith('/admin') && role === userRole.admin) {
      return true;
    } else if (url.startsWith('/partner') && role === userRole.partner) {
      return true;
    }
    return this.router.parseUrl('/auth');
  }
}

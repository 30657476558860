import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { errorCode, httpMethod, messageType } from '@core/constants/app.constants';
import { AlertToastrService } from '@core/services/alert-toastr.service';
import { LoggerService } from '@core/services/logger.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private toasterService: AlertToastrService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      let errorToastInInterceptor = true;
      if (
        ['POST', 'PATCH'].includes(request.method) &&
        request.body?.error_toast_in_interceptor != null &&
        !request.body?.error_toast_in_interceptor
      ) {
        errorToastInInterceptor = false;
        delete request.body.error_toast_in_interceptor
      }

      if (
        request.method === httpMethod.get &&
        request.headers?.get('X-CP-BIT') != null &&
        request.headers?.get('X-CP-BIT') === 'false'
      ) {
        errorToastInInterceptor = false;
      }

      if (request.url.includes('/auth/login')) {
        switch(error.error.status) {
          case errorCode.unauthorized:
            this.toasterService.displaySnackBarWithTranslation('toasterMessage.loginUnsuccessful', messageType.error);
            break;
          case errorCode.notFound:
            this.toasterService.displaySnackBarWithTranslation('toasterMessage.userNotFound', messageType.error);
            break;
          case errorCode.internalServer: 
            this.toasterService.displaySnackBarWithTranslation('toasterMessage.internalServerError', messageType.error);
            break;
        }
      } else if (error.error.status === errorCode.unauthorized) {
        this.toasterService.displaySnackBarWithTranslation('toasterMessage.tokenExpired', messageType.error);
        this.router.navigate(['/auth/logout']);
      } else if (request.url.includes('forgotPassword')) {
        this.toasterService.displaySnackBarWithTranslation('toasterMessage.forgotPasswordSuccessful', messageType.success);
        this.router.navigate(['/auth/login']);
      } else if (errorToastInInterceptor) {
        this.toasterService.displaySnackBarWithoutTranslation(error.error.message, messageType.error);
      }
      const err = new HttpErrorResponse({
        error: error.error,
        statusText: error.message,
        status: error.status
      })
      LoggerService.error(err);
      throw err;
    })
    )
  }

}

import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LOCAL_STORAGE_CONSTANT } from '@core/constants/localstorage.constant';
import { LocalStorageService } from '@core/services/local-storage.service';
import { APP_CONSTANTS, userRole } from '@core/constants/app.constants';
import { LoginResponse } from '@core/models/auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanMatch {

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) { }

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.LOGIN_TOKEN) as string;
    if (!token) {
      let lastVisitedUrl = '';
      segments?.forEach(segment => {
        lastVisitedUrl += `${segment.path}/`;
      })
      let url = 'auth/login';
      if (lastVisitedUrl && lastVisitedUrl !== 'partner/set-password/') {
        url = `/auth/login?${APP_CONSTANTS.REDIRECT_URL}=${lastVisitedUrl}`;
      }
      return this.router.parseUrl(url);
    } else {
      return true;
    }
  }
}

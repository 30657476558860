import { Routes } from "@angular/router";
import { AuthGuard } from "@core/guards/auth.guard";
import { LoginGuard } from "@core/guards/login.guard";
import { RoleGuard } from "@core/guards/role.guard";

export const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@core/constants/auth.routes').then((m) => m.authRoutes),
    canMatch: [LoginGuard],
  },
  {
    path: '',
    loadComponent: () => import('@pages/pages.component').then((m) => m.PagesComponent),
    canMatch: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/auth',
        pathMatch: 'full'
      },
      {
        path: 'admin',
        loadChildren: () => import('@core/constants/admin.routes').then((m) => m.adminRoutes),
        canActivate: [RoleGuard],
      },
      {
        path: 'partner',
        loadChildren: () => import('@core/constants/partner.routes').then((m) => m.partnerRoutes),
        canActivate: [RoleGuard],
      }
    ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
]